import LibbyPromoTemplate from './libby_promo.html.js';
import getQueryParams from '../../util/url/getQueryParams';
import cookieConfig from '../../../../lib/cookieConfig';

const { cookies } = window;
const { _ } = window;

export default class LibbyPromo extends Backbone.View {
    template = LibbyPromoTemplate;

    constructor({ bus }) {
        super(...arguments);
        this._views = [];
        this.bus = bus;
        this.hideInterceptShort();

        let queryParams = getQueryParams(window.location.search);
        this.setDestination(queryParams.dest);
        this.getSVGStatus();

        this._render();
    }

    getSVGStatus() {
        let browserName = bowser.name.toLowerCase();

        if (window.OverDrive.inApp && bowser.android) {
            if (
                !bowser.ios &&
                bowser.osversion !== undefined &&
                !isNaN(bowser.osversion.split('.')[0])
            ) {
                let majorVersion = parseInt(bowser.osversion.split('.')[0], 10);
                this.canUseSVG = majorVersion > 5.0;
            } else {
                this.canUseSVG = false;
            }
        } else {
            this.canUseSVG =
                !browserName.includes('opera') &&
                !browserName.includes('edge') &&
                !browserName.includes('explorer') &&
                !browserName.includes('safari');
        }
    }

    setDestination(dest) {
        // Reject absolute urls to prevent abuse
        if (!dest || /^(?:[a-z]+:)?:?\/\//i.test(dest)) {
            this.destination = '/';
        } else {
            this.destination = _.escape(dest);
        }
    }

    _render() {
        this.$el.empty();
        this.$el.append(this.template(this.destination, this.canUseSVG));

        this.$el.find('.promoLink').on('click', () => this.promoLinkClicked());
    }

    promoLinkClicked() {
        this.hideInterceptLong();
    }

    // we're using cookies to turn off the redirect to this page for
    // varying periods.  Users who actually followed an app store or "meet libby" link
    // get a longer break

    hideInterceptShort() {
        Cookies.set(cookieConfig.hideLibbyIntercept.name, 1, {
            expires: new Date(
                Date.now() + cookieConfig.hideLibbyIntercept.durationMS
            ),
            sameSite: 'lax',
            secure: true,
        });
    }

    hideInterceptLong() {
        Cookies.set(cookieConfig.hideLibbyInterceptLong.name, 1, {
            expires: new Date(
                Date.now() + cookieConfig.hideLibbyInterceptLong.durationMS
            ),
            sameSite: 'lax',
            secure: true,
        });
    }
}
